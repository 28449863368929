import React from 'react';
import {Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import PrivateRoute from 'components/PrivateRoute';

const UserRoles = ({match}) => (
    <div className="app-wrapper user-roles-rights-management">
        <Switch>
            <PrivateRoute requiredRoles={['super-admin', 'data-rights', 'database-rights']}
                          path={`${match.url}/dashboard`} component={asyncComponent(() => import('./dashboard'))}/>
            <PrivateRoute requiredRoles={['super-admin', 'data-rights', 'database-rights']} path={`${match.url}/roles`}
                          component={asyncComponent(() => import('./roles'))}/>
        </Switch>
    </div>
);

export default UserRoles;