import React from 'react';
import {Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const ServicePages = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/:id/modify`} component={asyncComponent(() => import('./modify'))}/>
            <Route path={`${match.url}/create`} component={asyncComponent(() => import('./modify'))}/>
            <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./dashboard'))}/>
        </Switch>
    </div>
);

export default ServicePages;