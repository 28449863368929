import React, {Component} from 'react';
import * as PropTypes from "prop-types";
import {Modal} from 'reactstrap';
import DrawerModal from "components/modals/DrawerModal";

export default class ModalContainer extends Component {

    static propTypes = {
        reference: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            header: null,
            body: null,
            footer: null,
            isOpen: false,
            className: '',
            keepOpened: false,
            containers: []
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if (this.state.containers.last.keepOpened) {
            return;
        }
        if (this.state.isOpen) {
            this.close();
        }
    }

    render() {
        const lastContainer = this.state.containers.last;
        if (!lastContainer) {
            return [];
        }
        let className = this.props.className ? this.props.className : '';
        return (
            <div className="text-center">
                {this.state.containers.map((container, containerIndex) => (
                    container.type === 'drawer' ?
                        <DrawerModal key={containerIndex} isOpen={this.state.isOpen} toggle={this.toggle}
                                     className={`layer ${container.className}`} {...container.props}>
                            {container.header}
                            {container.body}
                            {container.footer}
                        </DrawerModal> :
                        <Modal key={containerIndex} isOpen={this.state.isOpen} toggle={this.toggle}
                               className={`${className} ${container.className}`} onClosed={this.onClosed.bind(this)}>
                            {container.header}
                            {container.body}
                            {container.footer}
                        </Modal>
                ))}
            </div>
        );
    }

    onClosed() {
        this.setState({
            containers: []
        });
    }

    show(header, body, footer, className = '', keepOpened = false, type = 'modal', modalProps) {
        let containers = [...this.state.containers];
        containers.push({
            header,
            body,
            footer,
            className,
            keepOpened,
            type,
            props: modalProps || {}
        });
        this.setState({containers, isOpen: true});
    }

    close() {
        let containers = [...this.state.containers];
        if (containers.length > 0) {
            containers.splice(containers.length - 1, 1);
        }
        this.setState({
            containers,
            isOpen: containers.length !== 0
        });
    }

    componentDidMount() {
        if (this.props.reference) {
            this.props.reference(this);
        }
    }
}