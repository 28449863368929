import React from "react";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import DefaultLogo from "components/SidenavLogo/DefaultLogo";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import IntlMessages from "appUtil/IntlMessages";
import Helpers from "../../../ts/utils/Helpers";
import DatabaseLogoSettings from "components/DatabaseLogoSettings";
import * as _ from 'lodash';

class DatabaseLogo extends React.Component {

    constructor(props) {
        super(props);
        this.openSettings = this.openSettings.bind(this);
        this.saveSettings = this.saveSettings.bind(this);
        this.optionsExpandedChanged = this.optionsExpandedChanged.bind(this);
        this.onChangedSettings = this.onChangedSettings.bind(this);
        this.state = {
            databaseCatalog: null,
            database: null,
            group: null,
            settings: {},
            optionsExpanded: localStorage.getItem("optionsExpanded") === "1"
        }
    }

    componentDidMount() {
        this.props.settingsEventBus.addListener('optionsExpanded-changed', this.optionsExpandedChanged);
        this.checkDatabaseLogo();
        if (this.state.databaseCatalog && (!this.state.databaseCatalog.settings || !this.state.databaseCatalog.settings['database.logo'])) {
            if (!this.state.databaseCatalog.settings) {
                this.state.databaseCatalog.settings = {};
            }
            if (!this.state.databaseCatalog.settings['database.logo']) {
                this.state.databaseCatalog.settings['database.logo'] = {};
            }
            this.props.registryService.getDatabaseCatalogSettings(this.state.databaseCatalog.id, 'database.logo').then(response => {
                if (response.data) {
                    this.state.databaseCatalog.settings['database.logo'] = response.data;
                    this.setState({settings: response.data});
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.settingsEventBus.removeListener('optionsExpanded-changed', this.optionsExpandedChanged);
    }

    optionsExpandedChanged() {
        this.setState({
            optionsExpanded: localStorage.getItem("optionsExpanded") === "1"
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.database !== prevState.database) {
            this.checkDatabaseLogo();
        } else if (this.state.database && this.state.database.has_logo && !this.state.database) {
            let database = this.state.database;
            this.props.registryService.getDatabaseLogo(database.id).then(response => {
                database.logo_image = response.data.content;
                database.logo_image_mime_type = response.data.mine_type;
            });
        }
        if (this.state.databaseCatalog && (!this.state.databaseCatalog.settings || !this.state.databaseCatalog.settings['database.logo'])) {
            if (!this.state.databaseCatalog.settings) {
                this.state.databaseCatalog.settings = {};
            }
            if (!this.state.databaseCatalog.settings['database.logo']) {
                this.state.databaseCatalog.settings['database.logo'] = {};
            }
            this.props.registryService.getDatabaseCatalogSettings(this.state.databaseCatalog.id, 'database.logo').then(response => {
                if (response.data) {
                    this.state.databaseCatalog.settings['database.logo'] = response.data;
                    this.setState({settings: response.data});
                }
            });
        }
    }

    checkDatabaseLogo() {
        const database = this.state.database;
        if (database && database.has_logo && !database.logo_image) {
            this.props.registryService.getDatabaseLogo(database.id).then(response => {
                database.logo_image = response.data.content;
                database.logo_image_mime_type = response.data.mime_type;
                this.forceUpdate();
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const databaseId = nextProps.database ? nextProps.database.id : parseInt(nextProps.match.params.id);
        if (prevState.database && prevState.database.id === databaseId) {
            return {};
        }
        let databaseCatalog = null;
        let database = null;
        if (nextProps.database) {
            databaseCatalog = nextProps.database.catalog;
            database = nextProps.database;
        } else {
            databaseCatalog = nextProps.databaseList.filter(catalog => catalog.databases.filter(d => d.id === databaseId).length > 0).first;
            database = databaseCatalog ? databaseCatalog.databases.filter(d => d.id === databaseId).first : null;
        }
        let group = null;
        let settings = {}
        if (databaseCatalog) {
            if (databaseCatalog.group) {
                group = databaseCatalog.group;
            } else if (databaseCatalog.group_id) {
                group = nextProps.databaseGroups.find(g => g.id === databaseCatalog.group_id);
            }
            if (databaseCatalog.settings && databaseCatalog.settings['database.logo']) {
                settings = databaseCatalog.settings['database.logo'];
            }
        }

        return {databaseCatalog, database, group, settings}
    }

    render() {
        const {databaseCatalog, optionsExpanded} = this.state;
        if (!databaseCatalog) {
            return <DefaultLogo/>
        } else {
            return (
                <div className="d-flex align-items-center header-logo-group">
                    {this.renderLogo()}
                    {optionsExpanded && <i className="pointer fas fa-cog ml-1" onClick={this.openSettings}/>}
                </div>
            )
        }
    }

    openSettings() {
        this.settingsDataTmp = Helpers.deepCopy(this.state.settings);
        const {group} = this.state;
        if (!this.settingsDataTmp.orderElements) {
            this.settingsDataTmp.orderElements = ['databaseLogo', 'databaseInstitution'];
        }
        if (this.settingsDataTmp.orderElements.indexOf('groupLogo') === -1) {
            this.settingsDataTmp.orderElements.push('groupLogo');
        }
        if (this.settingsDataTmp.orderElements.indexOf('groupTitle') === -1) {
            this.settingsDataTmp.orderElements.push('groupTitle');
        }
        if (!this.settingsDataTmp.visible) {
            this.settingsDataTmp.visible = {
                groupLogo: true,
                groupTitle: true,
                databaseLogo: true,
                databaseInstitution: true,
            }
        }
        if (!group) {
            this.settingsDataTmp.orderElements.splice(this.settingsDataTmp.orderElements.indexOf('groupLogo'), 1);
            this.settingsDataTmp.orderElements.splice(this.settingsDataTmp.orderElements.indexOf('groupTitle'), 1);
        }
        this.props.modalService.show(
            <ModalHeader toggle={this.props.modalService.close}><IntlMessages id="settings"/></ModalHeader>,
            <ModalBody>
                <DatabaseLogoSettings data={this.settingsDataTmp} onChange={this.onChangedSettings}/>
            </ModalBody>, <ModalFooter>
                <Button style={{textTransform: "initial"}} color="success" onClick={this.saveSettings}>
                    <IntlMessages id="common.ok"/>
                </Button>
            </ModalFooter>, "database-logo-settings", false, 'drawer', {width: '25vw', sizable: true});
    }

    saveSettings() {
        this.props.modalService.close();
        let settings = _.merge(this.state.settings, this.settingsDataTmp);
        this.setState({settings});
        const {databaseCatalog} = this.state;
        if (!databaseCatalog.settings) {
            databaseCatalog.settings = {};
        }
        databaseCatalog.settings['database.logo'] = settings;
        this.props.registryService.saveDatabaseCatalogSettings(databaseCatalog.id, 'database.logo', settings).then(() => {
        });
    }

    onChangedSettings(data) {
        this.settingsDataTmp = data;
    }

    renderLogo() {
        const {databaseCatalog, database, group, settings} = this.state;
        if (settings.visible && settings.orderElements) {
            let elements = [];
            for (const elementKey of settings.orderElements) {
                if (settings.visible[elementKey]) {
                    if (elementKey === 'groupLogo') {
                        if (group && group.has_logo) {
                            elements.push(<span key="groupLogo" onClick={this.goToDatabaseMain.bind(this, database.id)}
                                                className="db-image">
                                    <img src={`database/group/${group.id}/logo`} alt={""}/>
                                </span>);
                        }
                    } else if (elementKey === 'groupTitle') {
                        if (group) {
                            elements.push(<span key="groupTitle"
                                                className="db-group-name ml-3 mr-3">{`${group.name}`}</span>);
                        }
                    } else if (elementKey === 'databaseLogo') {
                        elements.push(this.renderDatabaseLogo());
                    } else if (elementKey === 'databaseInstitution') {
                        if (database && database.institution) {
                            elements.push(<span key={'databaseInstitution'}
                                                className="db-group-name ml-3">{`${database.institution}`}</span>)
                        }
                    }

                }
            }
            return (
                <div>
                    {elements}
                </div>
            )
        }
        if (database.has_logo) {
            return this.renderDatabaseLogo();
        } else {
            if (group && group.has_logo) {
                return (
                    <span className={"pointer d-flex align-items-center"}
                          onClick={this.goToDatabaseMain.bind(this, database.id)}>
                                <span className="db-image">
                                    <img src={`database/group/${group.id}/logo`} alt={""}/>
                                </span>
                        {database.name && <span className="db-name">{`${database.name}`}</span>}
                        {!database.name && <span className="db-group-name ml-3">{`${group.name}`}</span>}
                            </span>
                )
            }
            if (database.name) {
                return (
                    <span onClick={this.goToDatabaseMain.bind(this, database.id)}
                          className="pointer db-name">{`${database.name}`}</span>
                )
            } else {
                return (
                    <span onClick={this.goToDatabaseMain.bind(this, database.id)}
                          className="pointer db-logo">{databaseCatalog.code}</span>
                )
            }
        }
    }

    renderDatabaseLogo() {
        const {database} = this.state;
        if (database.name) {
            return (
                <span key={'databaseLogo'} className={"pointer"}
                      onClick={this.goToDatabaseMain.bind(this, database.id)}>
                            {database.logo_image && <span className="db-image">
                                <img src={`data:${database.logo_image_mime_type};base64,${database.logo_image}`}
                                     alt={""}/>
                            </span>}
                    <span className="db-name">{`${database.name}`}</span>
                        </span>
            )
        }
        return (
            <span key={'databaseLogo'} onClick={this.goToDatabaseMain.bind(this, database.id)}
                  className="pointer db-image">
                            {database.logo_image &&
                            <img src={`data:${database.logo_image_mime_type};base64,${database.logo_image}`}
                                 alt={""}/>}
                        </span>
        )

    }

    goToDatabaseMain(databaseId) {
        this.props.history.push(`/app/database/${databaseId}/dashboard`);
    }
}

const mapStateToProps = ({settings, database, services, auth}) => {
    const authUser = auth.authUser;
    const databaseList = database.list;
    const databaseGroups = database.groups;
    const registryService = services.registry;
    const modalService = services.modal;
    const settingsEventBus = settings.eventBus;
    const authService = services.auth;
    return {databaseList, databaseGroups, registryService, modalService, settingsEventBus, authUser, authService};
};
export default withRouter(connect(mapStateToProps)(DatabaseLogo));