import React from 'react';
import ReactDOM from 'react-dom';
import 'rc-drawer/assets/index.css';
import 'styles/bootstrap.scss'
import 'styles/app.scss';
import 'styles/app-rtl.scss';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import '../css/material-design-iconic-font/css/material-design-iconic-font.css';
import '../css/weather-icons/css/weather-icons-wind.css';
import '../css/animate.css';
import '../css/loader.css';
import '../css/react-notification/react-notifications.css';
import 'antd/dist/antd.css';
import "react-datetime/css/react-datetime.css";
import '../css/main.scss';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import "swagger-ui-react/swagger-ui.css";

const rootEl = document.getElementById('app-site');

if (SYSTEM_CODE) {
    require('../css/themes/' + SYSTEM_CODE + '/main.scss');
} else {
    require('../css/themes/default/main.scss');
}

// Create a reusable render method that we can call more than once
let render = () => {
    // Dynamically import our main App component, and render it
    const MainApp = require('./MainApp').default;
    ReactDOM.render(
        <MainApp/>,
        rootEl
    );
};

if (module.hot) {
    module.hot.accept('./MainApp', () => {
        const NextApp = require('./MainApp').default;
        render(
            <NextApp/>,
            rootEl
        );
    });
}

render();
