import AuthService from "../../ts/services/AuthService";
import apiClient from "../data/apiClient";
import RegistryService from "../../ts/services/RegistryService";
import ModalService from "../../ts/services/ModalService";
import TranslationService from "../../ts/services/TranslationService";
import UserService from "../../ts/services/UserService";
import StatisticService from "../../ts/services/StatisticService";
import LoggerService from "../../ts/services/LoggerService";
import GenericService from "../../ts/services/GenericService";
import NotificationService from "../../ts/services/NotificationService";

const INIT_STATE = {
    auth: new AuthService(apiClient),
    registry: new RegistryService(apiClient),
    modal: new ModalService(),
    translation: new TranslationService(apiClient),
    user: new UserService(apiClient),
    statistic: new StatisticService(apiClient),
    logger: new LoggerService(apiClient),
    generic: new GenericService(apiClient),
    notification: new NotificationService(apiClient)
};

export default (state = INIT_STATE, action) => {
    return state;
}
