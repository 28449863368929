import React, {Component} from "react";
import ToggleButton from "components/ToggleButton";
import connect from "react-redux/es/connect/connect";
import IntlMessages from "appUtil/IntlMessages";
import Notification from "appUtil/Notification";
import Helpers from "../../../ts/utils/Helpers";
import {NotificationManager} from "react-notifications";
import Tooltip from "components/Tooltip";

export class ToggleAutomaticDeployment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enabled: this.props.settingsData.automatic_deployment,
            loading: false
        };
        this.onChange = this.onChange.bind(this);
        this.superAdmin = Helpers.userHasRoles(props.authUser, ['super-admin']);
    }

    static propTypes = {};

    render() {
        return (
            <div className="toggle-automatic-deployment">
                <ToggleButton checked={this.state.enabled} onChange={this.onChange}/>
                <Tooltip placement="left">
                    <IntlMessages id={this.state.enabled ? "settings.automatic-deployment.on" : "settings.automatic-deployment.off"} />
                </Tooltip>
            </div>
        );
    }

    onChange(checked) {
        if (!this.superAdmin || this.state.loading) {
            return;
        }
        this.setState({loading: true});
        this.props.genericService.modifySettings({automatic_deployment: checked}).then(() => {
            this.setState({enabled: checked, loading: false});
            NotificationManager.success(<IntlMessages id={checked ? "turned-on" : "turned-off"}/>, <IntlMessages
                id="settings.automatic-deployment"/>, 5000);
            this.props.settingsData.automatic_deployment = checked;
        }).catch(reason => {
            this.setState({loading: false});
            Notification.error(<IntlMessages
                id="settings.updating-failed"/>, Helpers.getMessageFromErrorResponse(reason.response));
        })
    }
}

const mapStateToProps = ({settings, services, auth}) => {
    const genericService = services.generic;
    const settingsData = settings.data;
    const authUser = auth.authUser;
    return {
        genericService,
        settingsData,
        authUser
    };
};
export default connect(mapStateToProps)(ToggleAutomaticDeployment);