import React from 'react';
import {Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const ProfilePages = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}`} component={asyncComponent(() => import('./Profile'))}/>
        </Switch>
    </div>
);

export default ProfilePages;