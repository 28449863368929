import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import DatabasePages from "app/routes/database";
import DataPages from "app/routes/data";

import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION
} from './../constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import ProfilePages from "app/routes/profile";
import TranslationsPages from "app/routes/translations";
import ServicePages from "app/routes/service";
import Helpers from "../../ts/utils/Helpers";
import LogPages from "app/routes/log";
import UserRoles from "app/routes/user-roles";
import DiagramPages from "app/routes/diagram";

class App extends React.Component {
    render() {
        const {drawerType, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
        let classNames = [drawerStyle];

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        if (this.props.location.pathname.toLowerCase() === '/app') {
            const groupDatabase = Helpers.makeGroupDatabase(this.props.databaseList);
            const firstGroup = this.props.databaseGroups.filter(group => groupDatabase.hasOwnProperty(group.id)).first;
            const databaseCatalog = firstGroup ? groupDatabase[firstGroup.id].first : (groupDatabase.hasOwnProperty(-1) ? groupDatabase[-1].first : null);
            if (databaseCatalog) {
                const orderedVersionDatabases = databaseCatalog.databases.sort(Helpers.arrayCompareValues('version', 'DESC'));
                let database = orderedVersionDatabases.filter(d => !d.is_draft).first;
                if (database) {
                    return (
                        <Redirect to={`/app/database/${database.id}/dashboard`}/>
                    );
                }
                database = orderedVersionDatabases.first;
                if (database) {
                    return (
                        <Redirect to={`/app/database/${database.id}/modify`}/>
                    );
                }
            }
            return (
                <Redirect to="/app/database/create"/>
            );
        }
        if (/^\/app\/data\/[\w|-]+\/[\w|\\.]+\/[\w|-]+\/read-value\/.*/.exec(this.props.location.pathname)) {
            classNames.push("read-value-view-page")
        }
        if (this.props.location.pathname === '/app/translations') {
            classNames.push("translations-page")
        }
        if (!this.props.authUser) {
            classNames.push("guest-mode")
        }
        if (this.props.inIframe) {
            return (
                <div className={`app-container app-iframe ${classNames.join(" ")}`}>
                    {this.renderMainContent()}
                </div>
            );
        }
        return (
            <div className={`app-container ${classNames.join(" ")}`}>
                {this.props.authUser && <Sidebar/>}
                <div className="app-main-container">
                    <div className="app-header">
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        <TopNav styleName="app-top-header"/>}
                        <Header/>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                        <TopNav/>}
                    </div>
                    <main className="app-main-content-wrapper">
                        {this.renderMainContent()}
                        <Footer/>
                    </main>
                </div>
            </div>
        );
    }

    renderMainContent() {
        const {match} = this.props;
        return (
            <div className="app-main-content">
                <Switch>
                    <Route path={`${match.url}/user-roles`} component={UserRoles}/>
                    <Route path={`${match.url}/database`} component={DatabasePages}/>
                    <Route path={`${match.url}/log`} component={LogPages}/>
                    <Route path={`${match.url}/data`} component={DataPages}/>
                    <Route path={`${match.url}/profile`} component={ProfilePages}/>
                    <Route path={`${match.url}/translations`} component={TranslationsPages}/>
                    <Route path={`${match.url}/service`} component={ServicePages}/>
                    <Route path={`${match.url}/diagram`} component={DiagramPages}/>
                    <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                </Switch>
            </div>
        )
    }
}


const mapStateToProps = ({settings, database, auth}) => {
    const authUser = auth.authUser;
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;
    const databaseList = database.list;
    const databaseGroups = database.groups;
    const inIframe = Helpers.inIframe();
    return {drawerType, navigationStyle, horizontalNavPosition, databaseList, databaseGroups, authUser, inIframe}
};
export default withRouter(connect(mapStateToProps)(App));