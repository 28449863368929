import React from "react";
import * as PropTypes from "prop-types";
import {Overlay, Tooltip as TooltipBootstrap} from 'react-bootstrap';

export default class Tooltip extends React.Component {

    static propTypes = {
        placement: PropTypes.string.isRequired,
        className: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.parentNode = null;
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
    }

    componentWillUnmount() {
        if (this.parentNode) {
            this.parentNode.removeEventListener('mouseleave', this.onMouseLeave);
            this.parentNode.removeEventListener('mouseenter', this.onMouseEnter);
        }
    }

    onMouseLeave() {
        if (this.state.isOpen) {
            this.setState({isOpen: false});
        }
    }

    onMouseEnter() {
        if (!this.state.isOpen) {
            this.setState({isOpen: true});
        }
    }

    render() {
        if (this.parentNode) {
            const {placement, children} = this.props;

            return (
                <Overlay
                    show={this.state.isOpen}
                    placement={placement}
                    delay={{show: 250, hide: 400}}
                    target={this.parentNode}
                >
                    {(props) => <TooltipBootstrap {...props} className={this.props.className}>
                        {children}
                    </TooltipBootstrap>}
                </Overlay>
            )
        }
        return <div ref={d => {
            if (d) {
                this.parentNode = d.parentElement;
                if (this.parentNode) {
                    this.parentNode.addEventListener('mouseleave', this.onMouseLeave);
                    this.parentNode.addEventListener('mouseenter', this.onMouseEnter);
                }
                this.forceUpdate();
            }
        }}/>;
    }
}