import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import AppLocale, {settedAppLocaleData} from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import {setInitUrl} from '../actions/Auth';
import asyncComponent from "appUtil/asyncComponent";
import {NotificationContainer} from "react-notifications";
import ModalContainer from "containers/ModalContainer";
import * as moment from "moment";
import * as QueryString from "query-string"

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        search: QueryString.stringify({
                            backUrl: window.location.href
                        }),
                        state: {from: props.location}
                    }}
                />}
    />;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        if(!settedAppLocaleData) {

        }
    }

    componentDidMount() {
        if (this.props.initURL === '') {
            this.props.setInitUrl(this.props.history.location.pathname);
        }
    }

    render() {
        const {match, location, locale, authUser, initURL, isDirectionRTL} = this.props;
        if (location.pathname.trim() === '/') {
            if (authUser === null) {
                return ( <Redirect to={'/signin'}/> );
            } else if (initURL === '' || initURL === '/app' || initURL === '/signin') {
                return ( <Redirect to={'/app'}/> );
            }
            return ( <Redirect to={'/app'}/> );
        }

        // for RTL Support
        if (isDirectionRTL) {
            document.body.classList.add('rtl')
        } else {
            document.body.classList.remove('rtl');
        }
        moment.locale(locale.locale);
        const currentAppLocale = AppLocale[locale.locale];
        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
                onError={this.defaultErrorHandler.bind(this)}
            >
                <div className="app-main">
                    <NotificationContainer/>
                    <ModalContainer reference={this.registerModal.bind(this)}/>
                    <Switch>
                        {/*<RestrictedRoute path={`${match.url}app`} authUser={authUser}*/}
                        {/*                 component={MainApp}/>*/}

                        <Route path={`${match.url}app`} component={MainApp}/>
                        <Route path='/signin' component={SignIn}/>
                        <Route
                            component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                    </Switch>
                </div>
            </IntlProvider>
        );
    }

    defaultErrorHandler(message, e) {
        const matched = /\s\"([\w|\.|-|-]+)"/.exec(message);
        if(matched !== null) {
            const key = matched[1];
            AppLocale[this.props.locale.locale].messages[key] = key;
        }
        else {
            console.error(message);
        }
    }

    registerModal(modal) {
        this.props.modalService.registerModal(modal);
    }
}

const mapStateToProps = ({settings, auth, services}) => {
    const {locale, isDirectionRTL} = settings;
    const {authUser, initURL} = auth;
    const modalService = services.modal;
    return {locale, isDirectionRTL, authUser, initURL, modalService}
};

export default connect(mapStateToProps, {setInitUrl})(App);
