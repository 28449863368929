import ApiClient from "../../ts/utils/ApiClient";
import BaseApiUrl from "constants/BaseApiUrl";

const apiClient = new ApiClient(BaseApiUrl, null, (value) => {
    if(value) {
        const part = value.split(' ', 2);
        if(part.length === 2) {
            localStorage.setItem('user_id', part[1]);
        }
    }
});
export default apiClient;