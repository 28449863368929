import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import IntlMessages from 'appUtil/IntlMessages';
import CustomScrollbars from "appUtil/CustomScrollbars";
import connect from "react-redux/es/connect/connect";
import {Button, ModalHeader} from "reactstrap";
import Helpers from "../../../ts/utils/Helpers";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Tooltip from "components/Tooltip";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import DatabaseGroupModify from "components/DatabaseGroup/DatabaseGroupModify";
import SplitPane from "react-split-pane";
import cn from "classnames";

class SidenavContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            databaseList: [...this.props.databaseList.sort(Helpers.arrayCompareValues('order', 'ASC'))],
            databaseGroups: [...this.props.databaseGroups.sort(Helpers.arrayCompareValues('order', 'ASC'))],
            selectedGroup: null
        };
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.createDatabaseGroup = this.createDatabaseGroup.bind(this);
        this.onChangeDatabaseGroup = this.onChangeDatabaseGroup.bind(this);
        this.saveDatabaseGroup = this.saveDatabaseGroup.bind(this);
        this.closeCollapsedNav = this.closeCollapsedNav.bind(this);
        this.updatedDatabase = this.updatedDatabase.bind(this);
        this.canReadDatabase = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'database-read', null);
        this.canCreateDatabase = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'database-create', null);
        this.canUpdateDatabase = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'database-update', null);
        this.superAdmin = Helpers.userHasDatabaseCatalogPermission(this.props.authUser, 'super-admin', null);
    }

    updatedDatabase() {
        this.setState({
            databaseList: [...this.props.databaseList.sort(Helpers.arrayCompareValues('order', 'ASC'))]
        });
        this.refresh();
    }

    componentDidMount() {
        this.props.databaseEventBus.addListener('changed', this.updatedDatabase);
        let selectedDatabaseCatalog = null;
        if(this.props.location.pathname) {
            const matched = /^\/app\/database\/(\d+)\//.exec(this.props.location.pathname);
            if(matched) {
                const databaseId = parseInt(matched[1]);
                selectedDatabaseCatalog = this.state.databaseList.filter(cd => cd.databases.some(d => d.id === databaseId)).first;

            }
        }
        let selectedGroup = null;
        if(selectedDatabaseCatalog && selectedDatabaseCatalog.group_id) {
            selectedGroup = this.state.databaseGroups.filter(g => g.id === selectedDatabaseCatalog.group_id).first;
        }
        if(selectedGroup) {
            this.setState({selectedGroup});
            if(this.props.onOpenDatabaseGroup) {
                this.props.onOpenDatabaseGroup();
            }
        }
        else {
            if(this.props.onCloseDatabaseGroup) {
                this.props.onCloseDatabaseGroup();
            }
        }
        this.refresh();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.databaseGroups !== this.state.databaseGroups) {
            this.refresh();
        }
    }

    componentWillUnmount() {
        this.props.databaseEventBus.removeListener('changed', this.updatedDatabase);
    }

    refresh() {
        const {history} = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path

        //const subMenuLi = document.querySelectorAll('.sub-menu > li');
        // for (let i = 0; i < subMenuLi.length; i++) {
        //     subMenuLi[i].onclick = function (event) {
        //         event.stopPropagation();
        //     }
        // }

        const menuLi = this.containerDiv.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            if (menuLi[i].firstChild) {
                menuLi[i].firstChild.onclick = function (event) {
                    // for (let j = 0; j < menuLi.length; j++) {
                    //     const parentLi = that.closest(this, 'li');
                    //     if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                    //         menuLi[j].classList.remove('open')
                    //     }
                    // }
                    if (!event.target.classList.contains('group-config-icon')) {
                        this.parentElement.classList.toggle('open');
                    }
                    //event.stopPropagation();
                }
            }
        }
        const activeLi = this.containerDiv.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    goToDatabaseDiagram(group, e) {
        e.preventDefault();
        e.stopPropagation();
        this.closeCollapsedNav();
        this.props.history.push(`/app/diagram/database?groupId=${group.id}`);
    }

    onClickDatabaseGroup(group, e) {
        e.preventDefault();
        const selectedGroup = this.state.selectedGroup === group ? null : group;
        this.setState({
            selectedGroup
        });
        if (selectedGroup && this.props.onOpenDatabaseGroup) {
            this.props.onOpenDatabaseGroup();
        }
        else if(!selectedGroup && this.props.onCloseDatabaseGroup) {
            this.props.onCloseDatabaseGroup();
        }
    }

    renderGroupDatabase(groupDatabase, star = false) {
        const {selectedGroup} = this.state;
        const databaseGroups = this.superAdmin ? this.state.databaseGroups : this.state.databaseGroups.filter(g => groupDatabase.hasOwnProperty(g.id));
        let tmp = databaseGroups.map((group, groupIndex) => (
            <Draggable key={`group-${groupIndex}`} index={groupIndex} draggableId={`db-group-${groupIndex}`}>
                {(draggableProvided, draggableSnapshot) => {
                    return <li className={cn("menu", {open: group === selectedGroup})} ref={draggableProvided.innerRef}
                               {...draggableProvided.draggableProps}
                               {...draggableProvided.dragHandleProps}>
                        <Droppable droppableId={`db-group-${groupIndex}-${tmp.length}-t`} type={'database-item'}>
                            {(droppableProvided, droppableSnapshot2) => (
                                <a role="button" href="#"
                                   ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}
                                   onClick={this.onClickDatabaseGroup.bind(this, group)}>
                                    <span>{group.name}</span>
                                </a>
                            )}
                        </Droppable>
                    </li>
                }}
            </Draggable>
        ));
        if (groupDatabase.hasOwnProperty(-1)) {
            groupDatabase[-1].forEach(((databaseCatalog) => tmp.push(
                <Droppable key={tmp.length} droppableId={`db-group--1-${tmp.length}`} type={'database-item'}>
                    {(droppableProvided, droppableSnapshot) => {
                        return (
                            <li key={tmp.length}
                                className="menu no-arrow"
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                            >
                                {this.renderDatabaseMenuItem(databaseCatalog, star)}
                                {droppableProvided.placeholder}
                            </li>
                        )
                    }}
                </Droppable>
            )));
        } else {
            tmp.push(
                <Droppable key={tmp.length} droppableId={`db-group--1-${tmp.length}`} type={'database-item'}>
                    {(droppableProvided, droppableSnapshot) => (
                        <li key={tmp.length}
                            className="menu no-arrow"
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                        >
                            {droppableProvided.placeholder}
                        </li>
                    )}
                </Droppable>
            )
        }
        return tmp;
    }

    handleDragEnd(result) {
        if (!this.superAdmin || !result.destination || result.destination.droppableId === 'db-groups' && result.destination.index === result.source.index) {
            return false;
        }
        if (result.type === 'database-group') {
            let databaseGroups = [...this.state.databaseGroups];
            const [moved] = databaseGroups.splice(result.source.index, 1);
            databaseGroups.splice(result.destination.index, 0, moved);
            for (let i = 0; i < databaseGroups.length; i++) {
                databaseGroups[i].order = i;
            }
            this.setState({
                databaseGroups
            });
            this.props.registryService.moveDatabaseGroupOrder(moved.id, result.destination.index).then(response => {
            });
            return true
        } else {
            let databaseList = [...this.state.databaseList];
            let groupId = null;
            if (!result.destination.droppableId.startsWith('db-group--1')) {
                const matched = /^db-group-(\d+)/.exec(result.destination.droppableId);
                if (matched) {
                    groupId = parseInt(this.state.databaseGroups[matched[1]].id);
                }
            }

            const [moved] = databaseList.splice(result.source.index, 1);
            moved.group_id = groupId;
            databaseList.splice(result.destination.index, 0, moved);
            for (let i = 0; i < databaseList.length; i++) {
                databaseList[i].order = i;
            }
            this.setState({
                databaseList
            });
            this.props.registryService.moveDatabaseCatalogOrder(moved.id, result.destination.index, moved.group_id).then(response => {
            });
            return true;
        }
    }

    onCreatedDatabaseGroup(group) {
        let databaseGroups = [...this.state.databaseGroups];
        databaseGroups.push(group);
        this.setState({databaseGroups});
    }

    createDatabaseGroup(e) {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.focus();
        e.currentTarget.blur();
        this.databaseGroupData = null;
        this.props.modalService.show(
            <ModalHeader><IntlMessages id="database.group.create"/></ModalHeader>,
            <ModalBody>
                <DatabaseGroupModify onChange={this.onChangeDatabaseGroup}/>
            </ModalBody>, <ModalFooter>
                <Button style={{textTransform: "initial"}} color="primary"
                        onClick={this.saveDatabaseGroup}><IntlMessages id="common.save"/></Button>{' '}
                <Button color="secondary"
                        onClick={this.props.modalService.close}><IntlMessages id="common.cancel"/></Button>
            </ModalFooter>);
    }

    onChangeDatabaseGroup(data) {
        this.databaseGroupData = data;
    }

    editDatabaseGroup(databaseGroup, e) {
        e.preventDefault();
        e.stopPropagation();
        this.databaseGroupData = null;
        this.props.modalService.show(
            <ModalHeader>
                <IntlMessages id="database.group.edit"/>
                <i className="far fa-times pointer" onClick={this.props.modalService.close}/>
            </ModalHeader>,
            <ModalBody>
                <DatabaseGroupModify data={databaseGroup} onChange={this.onChangeDatabaseGroup}/>
            </ModalBody>,
            <ModalFooter>
                <i className="far fa-trash-alt group-delete-btn mr-auto"
                   onClick={this.deleteDatabaseGroup.bind(this, databaseGroup)}>
                    <Tooltip placement="right"><IntlMessages id="database.group.delete"/></Tooltip>
                </i>
                <Button style={{textTransform: "initial"}} color="success" className="mb-0"
                        onClick={this.saveDatabaseGroup}><IntlMessages id="common.save"/></Button>
            </ModalFooter>);
    }

    deleteDatabaseGroup(databaseGroup) {
        this.props.modalService.close();
        this.props.registryService.deleteDatabaseGroup(databaseGroup.id).then(response => {
            this.props.databaseList.filter(d => d.group_id === databaseGroup.id).forEach(d => d.group_id = null);
            this.props.databaseGroups.splice(this.props.databaseGroups.indexOf(databaseGroup), 1);
            let databaseGroups = this.state.databaseGroups.filter(g => g.id !== databaseGroup.id);
            this.setState({databaseGroups});
        });
    }

    saveDatabaseGroup() {
        this.props.modalService.close();
        if (!this.databaseGroupData) {
            return;
        }
        this.props.registryService.modifyDatabaseGroup(this.databaseGroupData).then(response => {
            let {databaseGroups} = this.state;
            if (response.status === 201) {
                databaseGroups.push(response.data);
                this.props.databaseGroups.push(response.data);
            } else {
                const index = databaseGroups.indexOf(databaseGroups.filter(g => g.id === response.data.id).first);
                databaseGroups[index] = response.data;
                this.props.databaseGroups[index] = response.data;
            }
            this.setState({databaseGroups: [...databaseGroups]});
        });
    }

    render() {
        const groupDatabase = Helpers.makeGroupDatabase(this.state.databaseList);

        return (
            <div>
                <ul className="nav-menu action-buttons">
                    {this.canCreateDatabase && <li className="menu no-arrow">
                        <NavLink to="/app/database/create" onClick={this.closeCollapsedNav}>
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.create"/></Tooltip>
                            <i className="fal fa-database"/>
                        </NavLink>
                    </li>}
                    {this.canCreateDatabase && <li className="menu no-arrow">
                        <button onClick={this.createDatabaseGroup}>
                            <Tooltip placement="top"><IntlMessages
                                id="sidebar.databases.group.create"/></Tooltip>
                            <i className="fal fa-folder-plus"/>
                        </button>
                    </li>}
                    {this.canReadDatabase && this.state.databaseList.length > 0 && <li className="menu no-arrow">
                        <NavLink to="/app/database/compare" onClick={this.closeCollapsedNav}>
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.compare"/></Tooltip>
                            <i className="zmdi zmdi-compare"/>
                        </NavLink>
                    </li>}
                    {this.canReadDatabase && this.state.databaseList.length > 0 && <li className="menu no-arrow">
                        <NavLink to="/app/diagram/database?groupId=-1" onClick={this.closeCollapsedNav}>
                            <Tooltip placement="top"><IntlMessages id="sidebar.databases.diagram"/></Tooltip>
                            <i className="fas fa-project-diagram"/>
                        </NavLink>
                    </li>}
                </ul>
                <div className="drawer-dbs-groups" ref={d => this.containerDiv = d}>
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                        <SplitPane split="vertical" defaultSize="50%">
                            {this.renderFirstColumn(groupDatabase)}
                            {this.renderSecondColumn(groupDatabase)}
                        </SplitPane>
                    </DragDropContext>
                </div>
            </div>
        );
    }

    renderFirstColumn(groupDatabase) {
        return (
            <CustomScrollbars className="scrollbar sidebar-draggable-area"
                              style={{height: 'calc(100vh - 125px)'}}>
                <Droppable droppableId={`db-groups`} type={'database-group'}>
                    {(droppableProvided, droppableSnapshot) => (
                        <ul className="nav-menu"
                            ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                            {this.renderGroupDatabase(groupDatabase)}
                            {droppableProvided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </CustomScrollbars>
        )
    }

    renderSecondColumn(groupDatabase) {
        const {selectedGroup} = this.state;
        if (!selectedGroup) {
            return <div/>;
        }
        const groupIndex = this.state.databaseGroups.indexOf(selectedGroup);
        return (
            <CustomScrollbars className="scrollbar sidebar-draggable-area"
                              style={{height: 'calc(100vh - 125px)'}}>
                <div className="db-group-options">
                    {this.canUpdateDatabase &&
                    <span className="db-group-option" onClick={this.editDatabaseGroup.bind(this, selectedGroup)}><i
                        className="fal fa-folder group-config-icon pointer"/><IntlMessages id="database.group.edit" /></span>}
                    {this.canReadDatabase && groupDatabase.hasOwnProperty(selectedGroup.id) && groupDatabase[selectedGroup.id].length > 1 &&
                    <span className="db-group-option" onClick={this.goToDatabaseDiagram.bind(this, selectedGroup)}><i
                        className="fal fa-project-diagram group-diagram-icon pointer"/><IntlMessages id="sidebar.databases.diagram" /></span>}
                </div>
                <Droppable droppableId={`db-group-${groupIndex}-0`} type={'database-item'}>
                    {(droppableProvided, droppableSnapshot) => (
                        <ul className="menu"
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}>
                            {groupDatabase.hasOwnProperty(selectedGroup.id) && groupDatabase[selectedGroup.id].map(((databaseCatalog, databaseCatalogIndex) => (
                                <li key={databaseCatalogIndex} className="menu no-arrow">
                                    {this.renderDatabaseMenuItem(databaseCatalog)}
                                </li>
                            )))}
                            {droppableProvided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </CustomScrollbars>
        )
    }

    closeCollapsedNav() {
        this.props.toggleCollapsedNav(false);
    }

    renderDatabaseMenuItem(databaseCatalog, star = false) {
        if (databaseCatalog.databases.length === 0) {
            return [];
        }
        const sorted = databaseCatalog.databases.sort(Helpers.arrayCompareValues('version', 'DESC'));
        const latestNonDraftDatabase = sorted.filter(d => !d.is_draft).first;
        const latestDatabase = latestNonDraftDatabase ? latestNonDraftDatabase : sorted.first;
        const draggableId = `db-${databaseCatalog.id}`;
        return (
            <Draggable key={draggableId} index={this.state.databaseList.indexOf(databaseCatalog)}
                       draggableId={draggableId}>
                {(draggableProvided, draggableSnapshot) => (
                    <NavLink key={draggableId} ref={draggableProvided.innerRef}
                             to={`/app/database/${latestDatabase.id}/dashboard`}
                             className="d-flex align-items-center" onClick={this.closeCollapsedNav}
                             {...draggableProvided.draggableProps}
                             {...draggableProvided.dragHandleProps}>
                        <i className="fad fa-database"/>
                        <span className="nav-text">
                            <span className="db-name mr-1">{databaseCatalog.name}</span>
                            <span className="db-code">({databaseCatalog.code})</span>
                        </span>
                    </NavLink>
                )}
            </Draggable>
        );
    }

    toggleFavouriteDatabaseCatalog(databaseCatalog, event) {
        event.stopPropagation();
        if (!this.props.authUser.settings) {
            this.props.authUser.settings = {};
        }
        if (!this.props.authUser.settings.favouriteDatabaseCatalogs) {
            this.props.authUser.settings.favouriteDatabaseCatalogs = [];
        }
        const index = this.props.authUser.settings.favouriteDatabaseCatalogs.indexOf(databaseCatalog.id);
        if (index === -1) {
            this.props.authUser.settings.favouriteDatabaseCatalogs.push(databaseCatalog.id);
        } else {
            this.props.authUser.settings.favouriteDatabaseCatalogs.splice(index, 1);
        }
        this.props.authService.saveSettings(this.props.authUser.settings).then(() => {
        });
        this.forceUpdate();
    }
}


const mapStateToProps = ({database, auth, services}) => {
    const databaseList = database.list;
    const databaseEventBus = database.eventBus;
    const databaseGroups = database.groups;
    const authUser = auth.authUser;
    const authService = services.auth;
    const registryService = services.registry;
    const modalService = services.modal;
    return {databaseList, authUser, authService, databaseGroups, registryService, modalService, databaseEventBus}
};

export default withRouter(connect(mapStateToProps, {})(SidenavContent));
