import React from 'react';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import configureStore, {history} from './store';
import {Router} from 'react-router'
import App from './containers/App';
import {setAppLocaleData, settedAppLocaleData} from "./lngProvider";
import AppLocale from "./lngProvider";
import Helpers from "../ts/utils/Helpers";
import apiClient from "./data/apiClient";

export const store = configureStore();

export default class MainApp extends React.Component {

    constructor(props) {
        super(props);
        let state = store.getState();
        this.state = {
            loading: true
        };
        this.unauthorizedHandler = this.unauthorizedHandler.bind(this);
        if(state.auth.authUser || DEMAND_AUTHORIZATION_HEADER === 'false') {
            this.state.loading = true;
            const authService = state.services.auth;
            if(DEMAND_AUTHORIZATION_HEADER !== 'false') {
                authService.setAuthorization('Bearer ' + state.auth.authUser);
            }
            authService.getUser().then(response => {
                state.auth.authUser = response.data;
                this.loadData();
            }).catch(reason => {
                if (reason && reason.response && reason.response.status === 401) {
                    authService.setAuthorization(null);
                    state.auth.authUser = null;
                } else {
                    console.error(reason);
                    this.loadData();
                }
            });
        } else {
            state.auth.authUser = null;
            this.loadData();
        }
        this.modal = null;
    }

    componentDidMount() {
        apiClient.registerEvent('response-unauthorized', this.unauthorizedHandler);
    }

    componentWillUnmount() {
        apiClient.unregisterEvent('response-unauthorized', this.unauthorizedHandler);
    }

    unauthorizedHandler() {
        let state = store.getState();
        if (state.auth.authUser) {
            this.setState({loading: true});
            state.auth.authUser = null;
            this.loadData();
        }
    }

    loadData() {
        let state = store.getState();
        let all = [];
        const genericService = state.services.generic;
        const authService = state.services.auth;
        all.push(authService.getAuthList().then(response => {
            state.auth.list = response.data;
        }));
        all.push(genericService.getSettings().then(response => {
            state.settings.data = response.data;
        }));
        const translationService = state.services.translation;
        if(!settedAppLocaleData) {
            all.push(translationService.getTranslations().then(response => {
                setAppLocaleData(response.data);
                state.settings.locale = AppLocale[state.settings.locale.locale];
            }));
        }
        if(state.auth.authUser) {
            const registryService = state.services.registry;
            if(Helpers.userHasDatabaseCatalogPermission(state.auth.authUser, 'database-read', null) ||
                Helpers.userHasDatabaseCatalogPermission(state.auth.authUser, 'data-read', null) ||
                Helpers.userHasDatabaseCatalogPermission(state.auth.authUser, 'database-create', null)) {
                all.push(registryService.getDatabaseGroups().then(response => {
                    state.database.groups = response.data;
                }));
            }
            if(Helpers.userHasDatabaseCatalogPermission(state.auth.authUser, 'database-read', null) || Helpers.userHasDatabaseCatalogPermission(state.auth.authUser, 'data-read', null)) {
                all.push(registryService.getDatabases().then(response => {
                    state.database.list = response.data;
                    state.database.list.forEach(catalog => {
                        catalog.databases = catalog.databases.filter(d => !d.is_disabled);
                        catalog.databases.forEach(database => database.catalog = catalog);
                    });
                }));
                all.push(registryService.getServices().then(response => {
                    state.database.services = response.data;
                }));
            }
            all.push(registryService.getTags().then(response => {
                state.database.tags = response.data;
            }));
        }
        Promise.all(all).then((data) => {
            this.setState({loading: false});
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
        });
    }

    render() {
        if(this.state.loading) {
            return <div className="loader">
                <svg className="circular" viewBox="25 25 50 50">
                    <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2"
                            strokeMiterlimit="10"/>
                </svg>
            </div>
        }
        return <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route path="/" component={App}/>
                </Switch>
            </Router>
        </Provider>;
    }
}

