import React from 'react';
import {Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import PrivateRoute from "components/PrivateRoute";

const DiagramPages = ({match}) => (
    <div className="app-diagram-wrapper">
        <Switch>
            <PrivateRoute requiredRoles={['database-read']} path={`${match.url}/database`}
                          component={asyncComponent(() => import('./database'))}/>
        </Switch>
    </div>
);

export default DiagramPages;