import React from 'react';
import {Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import PrivateRoute from '../../../components/PrivateRoute';

const DatabasePages = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <PrivateRoute requiredRoles={['database-update']} path={`${match.url}/:id/modify`}
                          component={asyncComponent(() => import('./modify'))}/>
            <PrivateRoute requiredRoles={['database-create']} path={`${match.url}/create`}
                          component={asyncComponent(() => import('./modify'))}/>
            <PrivateRoute requiredRoles={['database-read']} path={`${match.url}/compare`}
                          component={asyncComponent(() => import('./compare'))}/>
            <PrivateRoute requiredRoles={['database-read']} path={`${match.url}/get-list`}
                          component={asyncComponent(() => import('./get-list'))}/>
            <PrivateRoute requiredRoles={['database-read', 'data-read']} path={`${match.url}/:id/dashboard`}
                          component={asyncComponent(() => import('./dashboard'))}/>
            <PrivateRoute requiredRoles={['advanced-data-migration']} path={`${match.url}/:id/migration`}
                          component={asyncComponent(() => import('./migration'))}/>
            <PrivateRoute requiredRoles={['database-read', 'data-create']} path={`${match.url}/:id/import`}
                          component={asyncComponent(() => import('./import'))}/>
            <PrivateRoute requiredRoles={['database-read', 'data-read']} path={`${match.url}/:id/export`}
                          component={asyncComponent(() => import('./export'))}/>
            <PrivateRoute requiredRoles={['database-read']} path={`${match.url}/:id/service`}
                          component={asyncComponent(() => import('./service'))}/>
            <PrivateRoute requiredRoles={['database-read']} path={`${match.url}/:id/data-view`}
                          component={asyncComponent(() => import('./data-view'))}/>
            <PrivateRoute requiredRoles={['database-read', 'data-read']} path={`${match.url}/:id/report`}
                          component={asyncComponent(() => import('./report'))}/>
            <PrivateRoute requiredRoles={['super-admin']} path={`${match.url}/:id/management-system`}
                          component={asyncComponent(() => import('./management-system'))}/>
            <PrivateRoute requiredRoles={['super-admin']} path={`${match.url}/:id/audit-log`}
                          component={asyncComponent(() => import('./audit-log'))}/>
        </Switch>
    </div>
);

export default DatabasePages;