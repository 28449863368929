import React from 'react';
import {Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import PrivateRoute from "components/PrivateRoute";

const DataPages = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <PrivateRoute requiredRoles={['data-read']} path={`${match.url}/:id/detail`}
                          component={asyncComponent(() => import('./detail'))}/>
            <Route path={`${match.url}/:code/:version/view/:catalogId/:viewVersion`}
                   component={asyncComponent(() => import('./view'))}/>
            <Route path={`${match.url}/:code/:version/:uuid/read-value/:field(.*)`}
                   component={asyncComponent(() => import('./read-value'))}/>
        </Switch>
    </div>
);

export default DataPages;