import React from 'react';
import {Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import PrivateRoute from '../../../components/PrivateRoute';

const LogPages = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <PrivateRoute requiredRoles={['database-read']} path={`${match.url}`}
                          component={asyncComponent(() => import('./dashboard'))}/>
        </Switch>
    </div>
);

export default LogPages;