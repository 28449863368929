import React, {Component} from "react";
import * as PropTypes from "prop-types";

export default class ToggleButton extends Component{

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    static propTypes = {
        className: PropTypes.string,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func
    };

    render() {
        const {checked} = this.props;
        const className = this.props.className || '';
        return (
            <i className={`toggle-icon fad fa-toggle-${checked ? 'on on' : 'off'} ${className}`} onClick={this.onClick}/>
        );
    }

    onClick() {
        if(this.props.onChange) {
            this.props.onChange(!this.props.checked);
        }
    }
}