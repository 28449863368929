import React from 'react';
import {Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import PrivateRoute from "components/PrivateRoute";

const TranslationsPages = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <PrivateRoute requiredRoles={['super-admin', 'local-translation-update', 'global-translation-update', 'global-translation-publish']} path={`${match.url}`}
                          component={asyncComponent(() => import('./main'))}/>
        </Switch>
    </div>
);

export default TranslationsPages;